












































































































































import mixins from 'vue-typed-mixins'
import DefaultButton from '@/builder/sections/section/components/DefaultButton.vue'
import { BookingDynamicFormTypes } from '@/calendesk/models/BookingDynamicFormTypes'
import DraftElement from '@/builder/sections/mixins/DraftElement'

export default mixins(DraftElement).extend({
  name: 'FlexibleFormFields',
  props: {
    formElements: {
      type: Array,
      required: true
    },
    button: {
      type: Object,
      required: true
    }
  },
  components: { DefaultButton },
  data () {
    return {
      dynamicFormTypes: BookingDynamicFormTypes
    }
  }
})
