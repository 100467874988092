

















































import mixins from 'vue-typed-mixins'
import Section from '@/builder/sections/mixins/Section'
import DynamicFormData from '@/calendesk/models/DynamicFormData'
import FlexibleFormFields from '@/builder/sections/section/shared/components/FlexibleFormFields.vue'

export default mixins(Section).extend({
  name: 'FlexibleForm1',
  components: { FlexibleFormFields },
  computed: {
    formElements (): Array<DynamicFormData> {
      return this.data.configuration.wb_form__dynamic_form__
    }
  }
})
